import { createSlice } from '@reduxjs/toolkit';

export const initialState = {
  details: {},
  loading: false,
  error: false,
  operations: {
    userId: null,
    loading: false,
    error: false,
    list: [],
    currentPage: 0,
    rowsPerPage: 50,
    totalElements: 0,
    totalPages: 0
  }
};

const slice = createSlice({
  name: 'userDetails',
  initialState,
  reducers: {
    userDetailsRequested: (userDetails) => {
      userDetails.loading = true;
      userDetails.error = false;
    },
    userDetailsReceived: (userDetails, { payload }) => {
      userDetails.details = payload;
      userDetails.loading = false;
    },
    userDetailsRequestFailed: (userDetails) => {
      userDetails.loading = false;
      userDetails.error = true;
    },
    userOperationsRequested: (userDetails) => {
      userDetails.operations.loading = true;
      userDetails.operations.error = false;
    },
    userOperationsReceived: (userDetails, { payload }) => {
      const { content, pageNo, pageSize, totalElements, userId, totalPages } = payload;

      userDetails.operations.userId = userId;
      userDetails.operations.currentPage = pageNo;
      userDetails.operations.rowsPerPage = pageSize;
      userDetails.operations.totalElements = totalElements;
      userDetails.operations.totalPages = totalPages;
      userDetails.operations.list = content || [];
      userDetails.operations.loading = false;
    },
    userOperationsRequestFailed: (userDetails) => {
      userDetails.operations.loading = false;
      userDetails.operations.error = true;
    }
  }
});

export const {
  userDetailsRequested,
  userDetailsReceived,
  userDetailsRequestFailed,
  userOperationsRequested,
  userOperationsReceived,
  userOperationsRequestFailed
} = slice.actions;

export default slice.reducer;
