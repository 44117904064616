import { AppBar, Box, IconButton, Toolbar } from '@mui/material';
import { styled } from '@mui/material/styles';
import AccountButton from 'components/AccountButton';
import { Menu as MenuIcon } from 'icons/Menu';
import PropTypes from 'prop-types';

const NavbarRoot = styled(AppBar)(({ theme }) => ({
  backgroundColor: theme.palette.background.paper,
  ...(theme.palette.mode === 'light'
    ? {
        boxShadow: theme.shadows[3]
      }
    : {
        backgroundColor: theme.palette.background.paper,
        borderBottomColor: theme.palette.divider,
        borderBottomStyle: 'solid',
        borderBottomWidth: 1,
        boxShadow: 'none'
      })
}));

const Navbar = (props) => {
  const { onOpenSidebar, ...other } = props;

  return (
    <>
      <NavbarRoot
        sx={{
          left: {
            lg: 220
          },
          width: {
            lg: 'calc(100% - 220px)'
          }
        }}
        {...other}
      >
        <Toolbar
          disableGutters
          sx={{
            minHeight: 64,
            left: 0,
            px: 2
          }}
        >
          <IconButton
            onClick={onOpenSidebar}
            sx={{
              display: {
                xs: 'inline-flex',
                lg: 'none'
              }
            }}
          >
            <MenuIcon fontSize="small" />
          </IconButton>
          <Box sx={{ flexGrow: 1 }} />
          <AccountButton />
        </Toolbar>
      </NavbarRoot>
    </>
  );
};

Navbar.propTypes = {
  onOpenSidebar: PropTypes.func.isRequired
};

export default Navbar;
