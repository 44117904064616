import { createSlice } from '@reduxjs/toolkit';

export const initialState = {
  details: {},
  loading: false,
  error: false,
  users: {
    applicationId: null,
    loading: false,
    error: false,
    list: [],
    currentPage: 0,
    rowsPerPage: 5,
    totalElements: 0,
    totalPages: 0
  }
};

const slice = createSlice({
  name: 'applicationDetails',
  initialState,
  reducers: {
    applicationDetailsRequested: (applicationDetails) => {
      applicationDetails.loading = true;
      applicationDetails.error = false;
    },
    applicationDetailsReceived: (applicationDetails, { payload }) => {
      applicationDetails.details = payload;
      applicationDetails.loading = false;
    },
    applicationDetailsRequestFailed: (applicationDetails) => {
      applicationDetails.loading = false;
      applicationDetails.error = true;
    },
    applicationUsersRequested: (applicationDetails) => {
      applicationDetails.users.loading = true;
      applicationDetails.users.error = false;
    },
    applicationUsersReceived: (applicationDetails, { payload }) => {
      const { content, pageNo, pageSize, totalElements, applicationId, totalPages } = payload;

      applicationDetails.users.applicationId = applicationId;
      applicationDetails.users.currentPage = pageNo;
      applicationDetails.users.rowsPerPage = pageSize;
      applicationDetails.users.totalElements = totalElements;
      applicationDetails.users.totalPages = totalPages;
      applicationDetails.users.list = content || [];
      applicationDetails.users.loading = false;
    },
    applicationUsersRequestFailed: (applicationDetails) => {
      applicationDetails.users.loading = false;
      applicationDetails.users.error = true;
    }
  }
});

export const {
  applicationDetailsRequested,
  applicationDetailsReceived,
  applicationDetailsRequestFailed,
  applicationUsersRequested,
  applicationUsersReceived,
  applicationUsersRequestFailed
} = slice.actions;

export default slice.reducer;
