import { ClipboardList as ClipboardListIcon } from 'icons/ClipboardList';
import { Home as HomeIcon } from 'icons/Home';

export const getSections = () => [
  {
    title: 'sidebar.general',
    items: [
      {
        title: 'sidebar.dashboard',
        path: '/dashboard',
        icon: <HomeIcon fontSize="small" />
      }
    ]
  },
  {
    title: 'sidebar.management',
    items: [
      {
        title: 'sidebar.applications',
        path: '/applications',
        icon: <ClipboardListIcon fontSize="small" />
      }
    ]
  }
];
