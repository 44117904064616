import { List, ListSubheader } from '@mui/material';
import SidebarItem from 'components/Sidebar/SidebarItem';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';

const renderNavItems = ({ depth = 0, items, path }) => (
  <List disablePadding>
    {items.reduce((acc, item) => reduceChildRoutes({ acc, depth, item, path }), [])}
  </List>
);

const reduceChildRoutes = ({ acc, depth, item, path }) => {
  const intl = useIntl();

  const key = `${item.title}-${depth}`;
  const partialMatch = item.path ? path.includes(item.path) : false;
  const exactMatch = path.split('?')[0] === item.path;

  if (item.children) {
    acc.push(
      <SidebarItem
        active={partialMatch}
        chip={item.chip}
        depth={depth}
        icon={item.icon}
        info={item.info}
        key={key}
        open={partialMatch}
        path={item.path}
        title={intl.formatMessage({ id: item.title })}
      >
        {renderNavItems({
          depth: depth + 1,
          items: item.children,
          path
        })}
      </SidebarItem>
    );
  } else {
    acc.push(
      <SidebarItem
        active={exactMatch}
        chip={item.chip}
        depth={depth}
        icon={item.icon}
        info={item.info}
        key={key}
        path={item.path}
        title={intl.formatMessage({ id: item.title })}
      />
    );
  }

  return acc;
};

const SidebarSection = (props) => {
  const { items, path, title, ...other } = props;

  const intl = useIntl();

  const subHeader = (
    <ListSubheader
      disableGutters
      disableSticky
      sx={{
        color: 'neutral.500',
        fontSize: '0.75rem',
        fontWeight: 700,
        lineHeight: 2.5,
        ml: 4,
        textTransform: 'uppercase'
      }}
    >
      {intl.formatMessage({ id: title })}
    </ListSubheader>
  );

  return (
    <List subheader={subHeader} {...other}>
      {renderNavItems({
        items,
        path
      })}
    </List>
  );
};

SidebarSection.propTypes = {
  items: PropTypes.array.isRequired,
  path: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired
};

export default SidebarSection;
