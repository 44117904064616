import { Box, Divider, Drawer, useMediaQuery } from '@mui/material';
import Logo from 'components/Logo';
import Scrollbar from 'components/Scrollbar';
import SidebarSection from 'components/Sidebar/SidebarSection';
import PropTypes from 'prop-types';
import { useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { getSections } from 'utils/constants/sidebar-sections';

const sections = getSections();

const Sidebar = ({ onClose, open }) => {
  const lgUp = useMediaQuery((theme) => theme.breakpoints.up('lg'), {
    noSsr: true
  });
  const { pathname } = useLocation();

  const handlePathChange = () => {
    if (open) {
      onClose?.();
    }
  };

  useEffect(handlePathChange, [pathname]);

  const content = (
    <Scrollbar
      sx={{
        height: '100%',
        '& .simplebar-content': {
          height: '100%'
        }
      }}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          height: '100%'
        }}
      >
        <div>
          <Box sx={{ p: 3 }}>
            <Link to="/">
              <Logo
                sx={{
                  height: 42,
                  width: 42
                }}
              />
            </Link>
          </Box>
        </div>
        <Divider
          sx={{
            borderColor: 'neutral.700',
            my: 0
          }}
        />
        <Box sx={{ flexGrow: 1 }}>
          {sections.map((section) => (
            <SidebarSection
              key={section.title}
              path={pathname}
              sx={{
                mt: 2,
                '& + &': {
                  mt: 2
                }
              }}
              {...section}
            />
          ))}
        </Box>
      </Box>
    </Scrollbar>
  );

  if (lgUp) {
    return (
      <Drawer
        anchor="left"
        open
        PaperProps={{
          sx: {
            backgroundColor: 'neutral.900',
            borderRightColor: 'divider',
            borderRightStyle: 'solid',
            borderRightWidth: (theme) => (theme.palette.mode === 'dark' ? 1 : 0),
            color: '#FFFFFF',
            width: 220
          }
        }}
        variant="permanent"
      >
        {content}
      </Drawer>
    );
  }

  return (
    <Drawer
      anchor="left"
      onClose={onClose}
      open={open}
      PaperProps={{
        sx: {
          backgroundColor: 'neutral.900',
          color: '#FFFFFF',
          width: 220
        }
      }}
      sx={{ zIndex: (theme) => theme.zIndex.appBar + 100 }}
      variant="temporary"
    >
      {content}
    </Drawer>
  );
};

Sidebar.propTypes = {
  onClose: PropTypes.func,
  open: PropTypes.bool
};

export default Sidebar;
