import { styled } from '@mui/material/styles';
import { forwardRef } from 'react';
import SimpleBar from 'simplebar-react';
import 'simplebar/dist/simplebar.min.css';

const ScrollbarRoot = styled(SimpleBar)``;

const Scrollbar = forwardRef((props, ref) => {
  return <ScrollbarRoot ref={ref} {...props} />;
});

Scrollbar.displayName = 'Scrollbar';

export default Scrollbar;
