import PrivateRoute from 'components/PrivateRoute';
import SplashScreen from 'components/SplashScreen';
import MainLayout from 'layout/MainLayout';
import { Suspense } from 'react';
import { Route, Routes } from 'react-router-dom';
import routes from 'routes/routes';

const getRoutes = ({ path, component, isSecured = false }) => {
  let content;
  if (isSecured)
    content = (
      <Route key={path} path={path} element={<PrivateRoute />}>
        <Route path="" element={component} />
      </Route>
    );
  else content = <Route key={path} path={path} element={component} />;

  return content;
};

const AppRoutes = () => {
  const routesWithMainLayout = routes.filter(({ layout }) => layout === 'main');
  const routesWithoutLayout = routes.filter(({ layout }) => layout === null);

  return (
    <Suspense fallback={<SplashScreen />}>
      <Routes>
        {/* Main Layout */}
        <Route path="" element={<MainLayout />}>
          {routesWithMainLayout.map((route) => getRoutes(route))}
        </Route>

        {/* No Layout */}
        {routesWithoutLayout.map((route) => getRoutes(route))}
      </Routes>
    </Suspense>
  );
};

export default AppRoutes;
