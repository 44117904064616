import request from 'common/services/apiService/api';
import {
  applicationCreateDone,
  applicationCreateFailed,
  applicationCreateRequested,
  applicationsReceived,
  applicationsRequested,
  applicationsRequestFailed,
  applicationUpdateDone,
  applicationUpdateEnabledStatusDone,
  applicationUpdateEnabledStatusFailed,
  applicationUpdateEnabledStatusRequested,
  applicationUpdateFailed,
  applicationUpdateRequested
} from 'containers/ApplicationsContainer/redux/applicationsSlice';
import { all, call, put, takeLatest } from 'redux-saga/effects';

const networkError = { message: 'network issue', subErrors: [] };

const requestGetApplications = ({ currentPage, rowsPerPage }) => {
  return request(`/applications?pageNumber=${currentPage}&pageSize=${rowsPerPage}`);
};

const requestUpdatedEnabledStatusApplication = ({ id, enabled }) => {
  return request(`/applications/${id}`, {
    method: 'PATCH',
    payload: { enabled }
  });
};

const requestUpdateApplication = ({ id, data }) => {
  return request(`/applications/${id}`, {
    method: 'PATCH',
    payload: data
  });
};

const requestCreateApplication = ({ data }) => {
  return request(`/applications`, {
    method: 'POST',
    payload: data
  });
};

export function* handleGetApplications({ payload }) {
  try {
    const response = yield call(() => requestGetApplications(payload));
    const { data } = response;
    yield put(applicationsReceived(data));
  } catch (error) {
    yield put(applicationsRequestFailed(error.message));
  }
}

export function* handleUpdateEnabledStatusApplication({ payload }) {
  try {
    yield call(() => requestUpdatedEnabledStatusApplication(payload));
    yield put(applicationUpdateEnabledStatusDone(payload));
  } catch (error) {
    yield put(applicationUpdateEnabledStatusFailed(error.message));
  }
}

export function* handleUpdateApplication({ payload }) {
  try {
    yield call(() => requestUpdateApplication(payload));
    yield put(applicationUpdateDone(payload));
  } catch (error) {
    yield put(applicationUpdateFailed(error?.response?.data ?? networkError));
  }
}

export function* handleCreateApplication({ payload }) {
  try {
    const response = yield call(() => requestCreateApplication(payload));
    const { data: id } = response;
    yield put(applicationCreateDone({ ...payload, id }));
  } catch (error) {
    yield put(applicationCreateFailed(error?.response?.data ?? networkError));
  }
}

export function* applicationsSaga() {
  yield all([
    takeLatest(applicationsRequested.type, handleGetApplications),
    takeLatest(applicationUpdateEnabledStatusRequested.type, handleUpdateEnabledStatusApplication),
    takeLatest(applicationUpdateRequested.type, handleUpdateApplication),
    takeLatest(applicationCreateRequested.type, handleCreateApplication)
  ]);
}
