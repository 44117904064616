import { CssBaseline, ThemeProvider } from '@mui/material';
import { useKeycloak } from '@react-keycloak/web';
import SplashScreen from 'components/SplashScreen';
import { Toaster } from 'react-hot-toast';
import AppRoutes from 'routes';
import { createTheme } from 'theme';

const themeConfig = {
  direction: 'ltr',
  responsiveFontSizes: true
};

const App = () => {
  const { initialized } = useKeycloak();
  let content;

  if (!initialized) {
    content = <SplashScreen />;
  } else {
    content = <AppRoutes />;
  }

  return (
    <ThemeProvider theme={createTheme(themeConfig)}>
      <CssBaseline />
      <Toaster position="top-center" />
      {content}
    </ThemeProvider>
  );
};

export default App;
