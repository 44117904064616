import { Avatar, Box, Button, ButtonBase } from '@mui/material';
import userManager from 'common/services/apiService/authentication/userManager';
import AccountPopover from 'components/AccountPopover';
import { UserCircle as UserCircleIcon } from 'icons/UserCircle';
import { useRef, useState } from 'react';
import { FormattedMessage } from 'react-intl';

const AccountButton = () => {
  const anchorRef = useRef(null);
  const [openPopover, setOpenPopover] = useState(false);

  const handleOpenPopover = () => {
    setOpenPopover(true);
  };

  const handleClosePopover = () => {
    setOpenPopover(false);
  };

  if (!userManager.isLoggedIn()) {
    return (
      <Button onClick={userManager.doLogin} variant="contained" color="primary">
        <FormattedMessage id="navbar.login" />
      </Button>
    );
  }
  return (
    <>
      <Box
        component={ButtonBase}
        onClick={handleOpenPopover}
        ref={anchorRef}
        sx={{
          alignItems: 'center',
          display: 'flex',
          ml: 2
        }}
      >
        <Avatar
          sx={{
            height: 40,
            width: 40
          }}
          src={null}
        >
          <UserCircleIcon fontSize="small" />
        </Avatar>
      </Box>
      <AccountPopover
        anchorEl={anchorRef.current}
        onClose={handleClosePopover}
        open={openPopover}
      />
    </>
  );
};

export default AccountButton;
