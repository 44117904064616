import request from 'common/services/apiService/api';
import {
  applicationDetailsReceived,
  applicationDetailsRequested,
  applicationDetailsRequestFailed,
  applicationUsersReceived,
  applicationUsersRequested,
  applicationUsersRequestFailed
} from 'containers/ApplicationDetailsContainer/redux/applicationDetailsSlice';
import { all, call, put, takeLatest } from 'redux-saga/effects';

export const requestGetApplicationDetails = ({ applicationId }) => {
  return request(`/applications/${applicationId}`);
};

const requestGetApplicationUsers = ({ applicationId, currentPage, rowsPerPage }) => {
  return request(
    `/applications/${applicationId}/users?pageNumber=${currentPage}&pageSize=${rowsPerPage}`
  );
};

export function* handleGetApplicationDetails({ payload }) {
  try {
    const response = yield call(() => requestGetApplicationDetails(payload));
    const { data } = response;
    yield put(applicationDetailsReceived(data));
  } catch (error) {
    yield put(applicationDetailsRequestFailed(error.message));
  }
}

export function* handleGetApplicationUsers({ payload }) {
  try {
    const response = yield call(() => requestGetApplicationUsers(payload));
    const { data } = response;
    yield put(applicationUsersReceived({ applicationId: payload.applicationId, ...data }));
  } catch (error) {
    yield put(applicationUsersRequestFailed(error.message));
  }
}

export function* applicationDetailsSaga() {
  yield all([
    takeLatest(applicationDetailsRequested.type, handleGetApplicationDetails),
    takeLatest(applicationUsersRequested.type, handleGetApplicationUsers)
  ]);
}
