import Keycloak from 'keycloak-js';

const appPort = window.location.port ? `:${window.location.port}` : '';
const appURL = `${window.location.protocol}//${window.location.hostname}${appPort}`;

const keycloak = new Keycloak({
  url: window.ENV.KEYCLOAK_URL,
  realm: window.ENV.KEYCLOAK_REALM,
  clientId: window.ENV.KEYCLOAK_CLIENT_ID
});

const initOptions = {
  onLoad: 'check-sso',
  silentCheckSsoRedirectUri: `${appURL}/silent-check-sso.html`,
  pkceMethod: 'S256'
};

const doLogin = keycloak.login;

const doLogout = () => keycloak.logout({ redirectUri: appURL });

const getToken = () => keycloak.token;

const isLoggedIn = () => !!keycloak.token;

const updateToken = (successCallback) =>
  keycloak.updateToken(5).then(successCallback).catch(doLogin);

const getUsername = () => keycloak.tokenParsed?.preferred_username;

const hasRole = (roles) => roles.some((role) => keycloak.hasRealmRole(role));

const accountManagement = () => keycloak.accountManagement();

const userManager = {
  keycloak,
  initOptions,
  doLogin,
  doLogout,
  getToken,
  isLoggedIn,
  updateToken,
  getUsername,
  hasRole,
  accountManagement
};

export default userManager;
