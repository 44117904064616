import React from 'react';
import { Navigate } from 'react-router-dom';

const DashboardPage = React.lazy(() => import('pages/DashboardPage'));
const NoMatchPage = React.lazy(() => import('pages/NoMatchPage'));
const ApplicationsPage = React.lazy(() => import('pages/ApplicationsPage'));
const ApplicationDetailsPage = React.lazy(() => import('pages/ApplicationDetailsPage'));
const ApplicationCreatePage = React.lazy(() => import('pages/ApplicationCreatePage'));
const UserDetailsPage = React.lazy(() => import('pages/UserDetailsPage'));

const routes = [
  {
    path: '/',
    component: <Navigate to="/dashboard" replace />,
    layout: 'main'
  },
  {
    path: '/dashboard',
    component: <DashboardPage />,
    layout: 'main'
  },
  {
    path: '/applications',
    component: <ApplicationsPage />,
    layout: 'main',
    isSecured: true
  },
  {
    path: '/applications/create',
    component: <ApplicationCreatePage />,
    layout: 'main',
    isSecured: true
  },
  {
    path: '/applications/:applicationId',
    component: <ApplicationDetailsPage />,
    layout: 'main',
    isSecured: true
  },
  {
    path: '/users/:userId',
    component: <UserDetailsPage />,
    layout: 'main',
    isSecured: true
  },
  {
    path: '*',
    component: <NoMatchPage />,
    layout: null
  }
];

export default routes;
