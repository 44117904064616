import { Box, styled } from '@mui/material';
import Sidebar from 'components/Sidebar';
import { useState } from 'react';
import Navbar from 'components/Navbar';
import { Outlet } from 'react-router-dom';

const MainLayoutRoot = styled('div')(({ theme }) => ({
  display: 'flex',
  flex: '1 1 auto',
  maxWidth: '100%',
  paddingTop: 26,
  [theme.breakpoints.up('lg')]: {
    paddingLeft: 220
  }
}));

export const MainLayout = () => {
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);

  return (
    <>
      <MainLayoutRoot>
        <Box
          sx={{
            display: 'flex',
            flex: '1 1 auto',
            flexDirection: 'column',
            width: '100%'
          }}
        >
          <Outlet />
        </Box>
      </MainLayoutRoot>
      <Navbar onOpenSidebar={() => setIsSidebarOpen(true)} />
      <Sidebar onClose={() => setIsSidebarOpen(false)} open={isSidebarOpen} />
    </>
  );
};
