import React, { useState } from 'react';

import English from 'common/intl/lang/en.json';
import French from 'common/intl/lang/fr.json';
import { IntlProvider } from 'react-intl';
import PropTypes from 'prop-types';

// Get user prefered language
export const Context = React.createContext();

const localeNavigator = navigator.language;
const lang = localeNavigator === 'fr' ? French : English;

const Wrapper = ({ children }) => {
  const [locale, setLocale] = useState(localeNavigator);
  const [messages, setMessages] = useState(lang);

  const selectLanguage = (e) => {
    const newLocale = e.target.value;
    setLocale(newLocale);
    if (newLocale === 'fr') {
      setMessages(French);
    } else {
      setMessages(English);
    }
  };

  return (
    <Context.Provider value={{ locale, selectLanguage }}>
      <IntlProvider messages={messages} locale={locale}>
        {children}
      </IntlProvider>
    </Context.Provider>
  );
};

Wrapper.propTypes = {
  children: PropTypes.any
};

export default Wrapper;
