import axios from 'axios';
import { stringify } from 'qs';

const request = (url, options = {}) => {
  const config = {
    method: 'GET',
    ...options
  };

  const errors = [];

  if (!url) {
    errors.push('url');
  }

  if (
    !config.payload &&
    config.method !== 'GET' &&
    config.method !== 'DELETE' &&
    config.method !== 'PATCH'
  ) {
    errors.push('payload');
  }

  if (errors.length) {
    throw new Error(`Error! You must pass \`${errors.join('`, `')}\``);
  }

  const headers = {
    Accept: 'application/json',
    'Content-Type': 'application/json',
    ...config.headers
  };

  const paramsIn = { ...config.params };
  const params = {
    url,
    headers,
    method: config.method,
    params: paramsIn,
    ...config.extra,
    paramsSerializer: (p) => stringify(p, { indices: false })
  };

  if (params.method !== 'GET') {
    params.data =
      params.headers && params.headers['Content-Type'] === 'application/json'
        ? JSON.stringify(config.payload)
        : config.payload;
  }

  return axios(params);
};
export default request;
