import { createSlice } from '@reduxjs/toolkit';

const errorsInitialState = {
  code: null,
  message: null,
  subErrors: []
};

export const initialState = {
  list: [],
  loading: false,
  error: false,
  pagination: {
    currentPage: 0,
    rowsPerPage: 5,
    totalElements: 0
  },
  update: {
    loading: false,
    errors: errorsInitialState
  },
  creation: {
    createdApplicationId: null,
    loading: false,
    errors: errorsInitialState
  }
};

const slice = createSlice({
  name: 'applications',
  initialState,
  reducers: {
    applicationsRequested: (applications) => {
      applications.loading = true;
      applications.error = false;
    },
    applicationsReceived: (applications, action) => {
      const { payload } = action;
      const { content, pageNo, pageSize, totalElements } = payload;

      applications.pagination.currentPage = pageNo;
      applications.pagination.rowsPerPage = pageSize;
      applications.pagination.totalElements = totalElements;

      applications.list = content;
      applications.loading = false;
    },
    applicationsRequestFailed: (applications) => {
      applications.loading = false;
      applications.error = true;
    },
    applicationUpdateEnabledStatusRequested: (applications) => {
      applications.update.loading = true;
    },
    applicationUpdateEnabledStatusDone: (applications, action) => {
      applications.update.loading = false;
      const { id: applicationId, enabled } = action.payload;
      applications.list.forEach((app) => {
        if (app.id === applicationId) {
          app.enabled = enabled;
        }
      });
    },
    applicationUpdateEnabledStatusFailed: (applications) => {
      applications.update.loading = false;
    },
    applicationUpdateRequested: (applications) => {
      applications.update.loading = true;
      applications.update.errors = errorsInitialState;
    },
    applicationUpdateDone: (applications, { payload }) => {
      applications.update.loading = false;
      applications.update.errors = errorsInitialState;

      const { id, data } = payload;
      applications.list.forEach((app) => {
        if (app.id === id) {
          app.name = data.name;
          app.description = data.description;
          app.clientId = data.clientId;
        }
      });
    },
    applicationUpdateFailed: (applications, { payload }) => {
      applications.update.loading = false;
      const { code, message, subErrors } = payload;
      applications.update.errors.message = message;
      applications.update.errors.subErrors = subErrors;
      applications.update.errors.code = code;
    },
    applicationSetEmptyErrors: (applications) => {
      applications.creation.errors = errorsInitialState;
      applications.update.errors = errorsInitialState;
    },
    applicationCreateRequested: (applications) => {
      applications.creation.createdApplicationId = null;
      applications.creation.loading = true;
      applications.creation.errors = errorsInitialState;
    },
    applicationCreateDone: (applications, { payload }) => {
      applications.creation.loading = false;
      applications.creation.errors = errorsInitialState;

      const { id, data } = payload;
      applications.creation.createdApplicationId = id;
      applications.list = [{ id, ...data }, ...applications.list];
    },
    applicationCreateFailed: (applications, { payload }) => {
      applications.creation.loading = false;
      const { code, message, subErrors } = payload;
      applications.creation.errors.code = code;
      applications.creation.errors.message = message;
      applications.creation.errors.subErrors = subErrors;
    }
  }
});

export const {
  applicationsRequested,
  applicationsReceived,
  applicationsRequestFailed,
  applicationUpdateEnabledStatusRequested,
  applicationUpdateEnabledStatusDone,
  applicationUpdateEnabledStatusFailed,
  applicationUpdateRequested,
  applicationUpdateDone,
  applicationUpdateFailed,
  applicationCreateRequested,
  applicationCreateDone,
  applicationCreateFailed,
  applicationSetEmptyErrors
} = slice.actions;

export default slice.reducer;
