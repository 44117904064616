import { configureStore } from '@reduxjs/toolkit';
import rootReducer from 'common/store/reducer';
import createSagaMiddleware from 'redux-saga';

export default () => {
  const sagaMiddleware = createSagaMiddleware();

  const store = configureStore({
    reducer: rootReducer,
    middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat([sagaMiddleware]),
    devTools: window.ENV.ENVIRONMENT === 'dev'
  });

  store.runSaga = sagaMiddleware.run;

  return store;
};
