import { createTheme as baseCreateTheme, responsiveFontSizes } from '@mui/material';
import { baseThemeOptions } from 'theme/baseThemeOptions';
import { lightThemeOptions } from './lightThemeOptions';

export const createTheme = (config) => {
  let theme = baseCreateTheme(baseThemeOptions, lightThemeOptions, {
    direction: config.direction
  });

  if (config.responsiveFontSizes) {
    theme = responsiveFontSizes(theme);
  }

  return theme;
};
