import applicationDetailsReducer from 'containers/ApplicationDetailsContainer/redux/applicationDetailsSlice';
import applicationsReducer from 'containers/ApplicationsContainer/redux/applicationsSlice';
import userDetailsReducer from 'containers/UserDetailsContainer/redux/userDetailsSlice';
import { combineReducers } from 'redux';

export default combineReducers({
  applications: applicationsReducer,
  applicationDetails: applicationDetailsReducer,
  userDetails: userDetailsReducer
});
