import request from 'common/services/apiService/api';
import {
  userDetailsReceived,
  userDetailsRequested,
  userDetailsRequestFailed,
  userOperationsReceived,
  userOperationsRequested,
  userOperationsRequestFailed
} from 'containers/UserDetailsContainer/redux/userDetailsSlice';
import { all, call, put, takeLatest } from 'redux-saga/effects';

export const requestGetUserDetails = ({ userId }) => {
  return request(`/users/${userId}`);
};

const requestGetUserOperations = ({ userId, currentPage, rowsPerPage }) => {
  return request(`/users/${userId}/operations?pageNumber=${currentPage}&pageSize=${rowsPerPage}`);
};

export function* handleGetUserDetails({ payload }) {
  try {
    const response = yield call(() => requestGetUserDetails(payload));
    const { data } = response;
    yield put(userDetailsReceived(data));
  } catch (error) {
    yield put(userDetailsRequestFailed(error.message));
  }
}

export function* handleGetUserOperations({ payload }) {
  try {
    const response = yield call(() => requestGetUserOperations(payload));
    const { data } = response;
    yield put(userOperationsReceived({ userId: payload.userId, ...data }));
  } catch (error) {
    yield put(userOperationsRequestFailed(error.message));
  }
}

export function* userDetailsSaga() {
  yield all([
    takeLatest(userDetailsRequested.type, handleGetUserDetails),
    takeLatest(userOperationsRequested.type, handleGetUserOperations)
  ]);
}
