import { ReactKeycloakProvider } from '@react-keycloak/web';
import 'assets/styles/index.css';
import IntlWrapper from 'common/intl/Wrapper';
import rootSaga from 'common/sagas';
import interceptor from 'common/services/apiService/api/interceptor';
import userManager from 'common/services/apiService/authentication/userManager';
import configureStore from 'common/store/configureStore';
import React from 'react';
import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import App from './App';
import reportWebVitals from './reportWebVitals';

const store = configureStore();
store.runSaga(rootSaga);

interceptor();

ReactDOM.createRoot(document.getElementById('root')).render(
  <ReactKeycloakProvider initOptions={userManager.initOptions} authClient={userManager.keycloak}>
    <IntlWrapper>
      <Provider store={store}>
        <BrowserRouter>
          <App />
        </BrowserRouter>
      </Provider>
    </IntlWrapper>
  </ReactKeycloakProvider>
);

reportWebVitals();
