import LogoSVG from 'assets/images/awb-logo.png';
import PropTypes from 'prop-types';

const Logo = ({ sx }) => {
  return <img height={sx.height} src={LogoSVG} alt="Logo" />;
};

Logo.propTypes = {
  sx: PropTypes.object
};

Logo.defaultProps = {
  sx: {
    height: 42
  }
};

export default Logo;
