import axios from 'axios';
import userManager from 'common/services/apiService/authentication/userManager';

const { BASE_API_URL } = window.ENV;

export default () => {
  axios.interceptors.request.use((config) => {
    const headers = {
      ...config.headers,
      'Access-Control-Allow-Origin': '*'
    };

    const accessToken = userManager.getToken();
    if (accessToken) headers.Authorization = `Bearer ${accessToken}`;

    return {
      ...config,
      url: `${BASE_API_URL}${config.url}`,
      headers
    };
  });

  axios.interceptors.response.use(
    (response) => {
      return response;
    },
    (error) => {
      const { response } = error;

      if (response) {
        const { status } = response;
        if (status === 403) {
          userManager.doLogout();
        }
      }
      return Promise.reject(error);
    }
  );
};
